import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import slugify from "slugify";
import Layout from "../components/layout";
import { Title, Flex, Grid, Box } from "../utils/system";
import Header from "../components/Header";

const CaseStudy = ({ data }) => {
  const projects = data.allSanityProject.nodes;
  return (
    <Layout>
      <Header title="Case Studies" subtitle="" />
      <Flex mb={6}>
        <Grid gridGap={4} gridTemplateColumns={["1fr ", "1fr 1fr"]} width="90%">
          {projects &&
            projects.map((item, key) => (
              <Link
                to={`/case-studies/${slugify(item.title, { lower: true })}`}
              >
                <Box>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.title}
                  />
                  <Title mb={1}>{item.title}</Title>
                </Box>
              </Link>
            ))}
        </Grid>
      </Flex>
    </Layout>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
  query Projects {
    allSanityProject {
      nodes {
        title
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.6)
          }
        }
      }
    }
  }
`;
